<template>
  <base-table-row
    class="tariffs-table-item"
    :columns="columns"
    v-bind="item"
    col-class="tariffs-table-item__col"
  >
    <template #name>
      <div class="tariffs-table-item medium-text">
        {{ item.name }}
      </div>
      <div class="tariffs-table-item__specs note-text">
        {{ item.description }}
      </div>
    </template>
    <template #description>
      <div class="tariffs-table-item__ip">
        {{ item.description }}
      </div>
    </template>
    <template #created>
      <div class="tariffs-table-item__ip">
        {{ created(item).split(' ')[0] }} <br />
        {{ created(item).split(' ')[1] }}
      </div>
    </template>
    <template #disk>
      <div v-if="!checked" class="tariffs-table-item__ip">
        {{ diskName(item) }}
      </div>
      <router-link
        v-else
        :to="{ name: 'Disk', params: { diskid: item.volume_id } }"
        class="tariffs-table-item__link medium-text"
      >
        {{ diskName(item) }}
      </router-link>
    </template>
    <template #size>
      <div class="tariffs-table-item__ip">{{ item.size }} ГБ</div>
    </template>
    <template #status>
      <main-label :color="item.status.color" theme="plain" class="tariffs-table-item__status">
        {{ $t(`status.${item.status.key}`) }}
      </main-label>
    </template>
    <template #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="left"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="tariff-table-item__popover"
      >
        <plain-button icon="more" class="tariffs-table-item__more" @click="togglePopoverState" />
        <snap-context-menu slot="popover" :tariff="item" class="tariff-table-item__context-menu" />
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import MainLabel from '@/components/Labels/MainLabel.vue';
import SnapContextMenu from './SnapContextMenu';
import popoverFix from '@/mixins/popoverFix';
import { format } from 'date-fns';
export default {
  name: 'TariffsTableSnapshotRow',
  components: {
    BaseTableRow,
    MainLabel,
    SnapContextMenu,
  },
  mixins: [popoverFix],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    disks() {
      return this.$store.state.moduleStack.volumes;
    },
  },
  methods: {
    created(i) {
      return format(i.created_at, 'dd.MM.yyyy HH:mm:ss');
    },
    diskName(item) {
      return this.disks.find(x => x.id === item.volume_id)
        ? this.disks.find(x => x.id === item.volume_id).name
        : '';
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "activeUnder": "До {date} г.",
    "mb": "{num} МБ",
    "gb": "{num} ГБ"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table-item {
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: ellipsis;
      //text-overflow: unset;
      //overflow: visible;
      overflow: hidden;
      z-index: 2;
    }
  }
  &__link {
    position: relative;
    padding: 0.5em;
    margin: -0.5em;
    color: $primary-color;
    text-decoration: none;
    white-space: nowrap;
    background: inherit;
    &:hover {
      color: darken($primary-color, 20%);
    }

    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        display: block;
        size(2.5em, 100%);
        absolute(0px, 100%);
        background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }
  }
  //&__link {
  //  position: relative;
  //  padding: 0.5em;
  //  margin: -0.5em;
  //  color: inherit;
  //  text-decoration: none;
  //  white-space: nowrap;
  //  background: inherit;
  //
  //  &:hover::after {
  //    +breakpoint(ml-and-up) {
  //      content: '';
  //      display: block;
  //      size(2.5em, 100%);
  //      absolute(0px, 100%);
  //      background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
  //    }
  //  }
  //}
}
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-table-item {
  &__popover {
    text-align: left
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      width: calc(100vw - 6rem);
    }
  }
}
</style>
